html, body {
  height: 100%;
  overflow: auto;
  background-color: #18191b;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.text {
  color: #BCA37F;
}


.portfolio {
  color: white;
  font-size: calc(10px + 2vmin);
  overflow: auto;
  padding: 3%;
  padding-top: 5%;
}

.App-link {
  color: #61dafb;
}

.right-side {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
  margin: 0 auto;
  padding: 0 0 0 0;
  width: 100%;
  height: 100%;
}

.dashed {
  border-bottom: solid 1px #000000;
}

img {
  width: 500px;
  height: 600px;
}


a {
  color: white;
}

.rotate-text {
  /*display: inline-block;*/
  /*position: relative;*/
}

.rotate-neg-90 {
  position: absolute;
  transform-origin: top left;
  transform: translate(-50%, -50%) rotate(-90deg);
}
